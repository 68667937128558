body {
  background-color: whitesmoke;
  min-width: fit-content;
  max-height: 100%;
}

.main {
  position: relative;
  min-height: 100vh;
}

.app {
  min-height: 100%;
  height: 100%;
  padding-bottom: 47px;
}

.footer {
  background-color: #eee;
  position: absolute;
  bottom: 0;
  height: 48px;
  width: 100%;
  overflow: hidden;

}

.footer-links {
  margin:auto;
  max-width: 340px;
  font-size: .9em;
  color: #777;
  padding-top: 4px;
}

.footer-cpr {
  margin:auto;
  width: 250px;
  text-align: center;
  font-size: .7em;
  color: #999;
  padding-top: 6px;
}

.footer-links a {
  margin-left: 10px;
  text-decoration: none;
  color: #777;
}

.footer-links a:hover {
  text-decoration: underline;
  color: #333;
}

.footer-links .icon {
  width:20px;
  float: right;
}

.cookies {
  width: 100vw;
  text-align: center;
  background-color: #666;
  color: white;
  margin-top: 50px;
  padding: 1px 0 10px 0;
  position: absolute;
  bottom: 0;
  margin-bottom: 55px;
}

.cookie-content {
  max-width: 680px;
  margin: auto;
  text-align: left;
}

.cookies p {
  line-height: 1.2;
  padding: 0 10px 0 10px;
}

.cookies a {
  color: white;
}

.label-inline {
  display: inline;
}

/* Label classes */
.name {
  line-height: 30px;
  padding-left: 3px;
  background-color: white;
  font-size: .9em;
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.label-name {
  background-color: white;
  padding-left: 18px;
  height: 40px;
}

.input-name {
  line-height: 24px;
  background-color: white;
  font-size: .9em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;;
  box-sizing: border-box;
}

.heading {
  line-height: 30px;
  padding-left: 3px;
  background-color: white;
  font-size: 1em;
  font-weight: 600;
  height: 30px;
  white-space: nowrap;
  display: inline-block;
}

.heading-sl {
  padding-left: 10px;
  background-color: #F9F9F9;
  font-size: 0.8em;
  font-weight: 600;
  line-height: 32px;
  height: 27px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #3f51b5;
  border-top: 1px solid whitesmoke;
  border-bottom: 1px solid whitesmoke;
}

.label-heading {
  background-color: white;
  padding-left: 8px;
  height: 40px;
}

.input-heading {
  line-height: 24px;
  background-color: white;
  font-size: 1em;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
}

.title {
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 1.2em;
  padding-top:3px;
}

.label-title {
  display: inline;
}

.input-title {
  font-size: 1.2em;
  font-weight: 600;
  min-width: 400px;
  text-align: center;
  margin-bottom: 7px;
}
/* ------------- */

.step-slider {
  margin-top: 12px;
}

.step-slider-mark {
  margin-left: 20px;
}

.step-slider-mark-end {
  margin-left: -20px;
}

.start-label {
  font-size: .8em;
}
.edit-name {
  width: 240px;
}

.edit-colour {
  width: 100px;
}

.edit-border-colour {
  width: 100px;
  margin-left: 40px !important;
}

.start-select {
  width: 240px;
}

.row {
  height: 30px;
  width: 100%;
  display: flex;
}

.slider {
  line-height: 30px;
  padding-left: 10px;
  width: 100%;
  position: relative;
}

.row-spacer {
  height: 20px;
  width: 100%;
}

.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 0; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 20px;
  right: 0;
  bottom: 0;
  z-index: -1; /* Specify a stack order in case you're using a different order for other elements */
  border-left: 2px solid #F0F0F0;
}

.sub-headings {
  border: 2px solid #F0F0F0
}

.bg {
  z-index: -1; /* Specify a stack order in case you're using a different order for other elements */
  padding: 6px 10px 10px 10px;
  background-color: #F9F9F9;
  width: fit-content;
  top: 40px;
  margin: auto;
}

.title-bg {
  text-align: center;
}

.grid {
  display: grid;
  grid-auto-flow: row;
  background-color: whitesmoke;
  grid-column-gap: 1px;
  width:  fit-content;
  position: relative;
  overflow: hidden;
}

.time1 {
  text-align: center;
  font-size: .8em;
  background-color: white;
  line-height: 30px;
}
.time2 {
  text-align: center;
  font-size: .7em;
  background-color: white;
  margin-top: 1px;
  padding-top: 5px;
}

.left-time {
  text-align: left;
  padding-left: 5px;
}

.row-spacer {
  background-color: white
}

.drag-area {
  position: absolute;
  z-index: -1;
}

.bar-row {
  background-color: white
}

.bar {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px;
  line-height: 27px;
}

.bar span {
  margin-left: 7px;
  color: white;
  font-weight: 600;
  overflow: hidden;
}

.bar-start-rounded {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.bar-end-rounded {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.bar-start {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .bar-end {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

.milestone {
  display: inline-flex;
}


.toolbar {
  background-color: black;
  margin: auto;
  min-width: 10em;
}

.toolbar-bg {
  background-color: black;
  width: 100%;
}

.toolbar-right {
  float: right;
}

.edit-icon {
  padding-bottom: 14px;
  padding-left: 7px;
  padding-top: 6px;
  background-color: white;
}

.down-chevron {
  position: relative;
  top: -4px;
}

.toolbar-icon {
  margin-right: 2px;
}

.toolbar-icon-large {
  margin-right: 12px;
}

@media (max-width: 350px) {
  .toolbar-icon-large {
    margin-right: 8px;
  }
}

.settings {
  min-width: 320px;
}

.setting-label {
  display: inline-block;
  padding-right: 10px;
}

.settings div {
  margin-bottom: 10px;
}

.projects {
  position: relative;
}

.projects .item {
  min-width: 320px;
  margin: 2px 0 2px 0;
  line-height: 2em;
  vertical-align: middle;
  padding-left: 1em;
  font-weight: 400;
  border-radius: 4px;
  display: flex;
}

.projects-import .item {
    margin-bottom: 17px;
}

.projects-import .earlier {
    color: rgb(153, 43, 43);
}

.projects .project-name {
  width: 100%;
  margin-right: 30px;
  cursor: pointer;
}

.projects .selected {
  background-color: #F0F0F0;
}

.projects .item:hover {
  background-color: #EEEEEE;
}

.projects svg{
  vertical-align: middle !important;
  padding-bottom: 4px;
}

.project-info {
    font-size: 0.75rem;
    line-height: 1rem;
}

.overwrite-name {
    font-weight: bold;
}

.timestamp {
    color: #999;
}

.project-delete, .project-icon {
  position: absolute;
  right: 8px;
  color: #ffffff;
  cursor: pointer;
}

.projects .item:hover .project-delete {
  color: #999;
}

.projects .item:hover .project-delete:hover {
  color: red;
}


.projects .item:hover .project-delete {
    color: #999;
}

.projects .item:hover .project-icon:hover {
    color: blue;
}

.projects-toolbar {
  text-align: right;
  margin-bottom: 7px;
}

.projects-toolbar div {
  display: inline;
  margin-left: 5px;
  color: #666;
}

.edit-toolbar {
    text-align: right;
    margin: 7px 17px 0 0;
}

.edit-toolbar div {
    display: inline;
    margin-left: 5px;
    color: #666;
}


 .button-enabled:hover {
    cursor: pointer;
    color: blue;
 }

 .delete-button-enabled:hover {
    cursor: pointer;
    color: red;
 }

 .button-disabled {
    color:#DDD !important;
    cursor: default !important;
 }


 .button-selected {
    color: blue !important;
 }

 .delete-button-selected {
    color: red !important;
 }

 .project-settings {
    margin-top: 6px;
}

.project-settings .label {
    margin: 6px 0 6px 0;
    font-size: 0.75rem;
}

.label-disabled {
    color:  #ccc !important;
}

Label {
    color: rgba(0, 0, 0, 0.54);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.15008px;
    line-height: 24px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    transform: matrix(0.75, 0, 0, 0.75, 0, 1.5);
    transform-origin: 0px 0px;
    -moz-osx-font-smoothing: grayscale
}

.settings .bottom-row {
    margin-bottom: 20px;
}

.error-msg {
    color: rgb(244, 67, 54);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.39996px;
    line-height: 19.9167px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 3px;
    text-align: left;
    -moz-osx-font-smoothing: grayscale
}